<template>
    <div class="add_edit_vue">
        <!-- 标题 -->
        <div class="vue_title">
            <div class="back_btn" @click="backBtn"><i class="el-icon-arrow-left"></i>{{ id?'编辑声音':'添加声音' }}</div>
        </div>
        <!--  -->
        <div class="main_info">
            <!-- 模特基本信息 -->
            <div class="info_basic">
                <div class="basic_text">
                    <div class="left">归属专辑</div>
                    <div class="right">
                        <el-select v-model="info.pid" :clearable="true" placeholder="请选择专辑" @change="changePid">
                            <el-option v-for="item in albumList" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="basic_text">
                    <div class="left">声音类型</div>
                    <div class="right">
                        <el-select v-model="info.tts_platform" placeholder="请选择声音类型">
                            <el-option v-for="item in platformList" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="basic_text">
                    <div class="left"><span>*</span>声音名称</div>
                    <div class="right">
                        <el-input v-model="info.name" :clearable="true" placeholder="请输入声音名称"></el-input>
                    </div>
                </div>
                <div class="basic_text">
                    <div class="left"><span>*</span>标签</div>
                    <div class="right">
                        <el-input v-model="info.label" :clearable="true" placeholder="请输入您的个人标签，中间用 “逗号” 隔开"></el-input>
                    </div>
                </div>
                <div class="basic_text">
                    <div class="left"><span>*</span>点击热度</div>
                    <div class="right">
                        <el-input v-model="info.hits" @input="limitNum($event,'hits')" :clearable="true" placeholder="请输入点击热度"></el-input>
                    </div>
                </div>
                <div class="basic_text">
                    <div class="left"><span>*</span>钻石数</div>
                    <div class="right">
                        <el-input v-model="info.price" @input="limitNum($event,'price')" :clearable="true" placeholder="请输入钻石数"></el-input>
                    </div>
                </div>
                <div class="basic_text">
                    <div class="left"><span>*</span>是否会员</div>
                    <div class="right">
                        <el-select v-model="info.is_vip" :clearable="true" placeholder="请选择">
                            <el-option v-for="item in vipList" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="basic_text">
                    <div class="left"><span>*</span>是否免费</div>
                    <div class="right">
                        <el-select v-model="info.is_free" :clearable="true" placeholder="请选择">
                            <el-option v-for="item in freeList" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="basic_text">
                    <div class="left"><span>*</span>是否推荐</div>
                    <div class="right">
                        <el-select v-model="info.is_recom" :clearable="true" placeholder="请选择">
                            <el-option v-for="item in recomList" :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="basic_text">
                    <div class="left"><span>*</span>归属类型</div>
                    <div class="right">
                        <el-select v-model="info.category_id" multiple :clearable="true" placeholder="请选择类型">
                            <el-option v-for="item in categoryList" :key="item.id+''" :label="item.name" :value="item.id+''">
                            </el-option>
                        </el-select>
                    </div>
                </div>
                <div class="basic_text ">
                    <div class="left pd70"><span>*</span>声音头像</div>
                    <div class="right">
                        <file-upload key="img1" :width="100" :height="100" type="img_1" @upload="successUploadImg">
                            <div class="picture_img_upload">
                                <div class="img_yes" v-if="info.avatar">
                                    <el-image style="border-radius: 50%;" :src="info.avatar" fit="scale-down"></el-image>
                                </div>
                                <div class="img_no" v-else>
                                    <i class="el-icon-plus"></i>
                                    <div>上传头像</div>
                                </div>
                            </div>
                        </file-upload>
                    </div>
                </div>
                <div class="basic_text">
                    <div class="left pd70"><span>*</span>声音链接</div>
                    <div class="right">
                        <file-upload key="img1" :width="100" :height="100" type="audio_1" @upload="successUploadAudio">
                            <div class="el-upload-audio" v-if="info.audio_url">
                                <img class="img" src="@/assets/images/icon/audio_icon.png" alt="">
                                <p>{{info.audio_url_name}}</p>
                                <div class='succ_btn'>
                                    <i :class="audioPlay?'el-icon-video-pause':'el-icon-video-play'" @click.stop="playAudio(info.audio_url)"></i>
                                    <i class="el-icon-circle-plus-outline"></i>
                                </div>
                            </div>
                            <div class="picture_img_upload" v-else>
                                <div class="img_no">
                                    <i class="el-icon-plus"></i>
                                    <div>上传声音</div>
                                </div>
                            </div>
                        </file-upload>
                    </div>
                </div>

                <div class="basic_text">
                    <div class="left pd70"><span>*</span>应用场景</div>
                    <div class="right">
                        <el-input v-model="info.scenes" type="textarea" :rows="4" resize="none" maxlength="300" show-word-limit placeholder="请输入您的应用场景"></el-input>
                    </div>
                </div>
                <div class="basic_text">
                    <div class="left pd70"><span>*</span>声音标识</div>
                    <div class="right">
                        <el-input v-model="info.identity" type="textarea" :rows="4" resize="none" maxlength="300" show-word-limit placeholder="请输入发音人标识信息"></el-input>
                    </div>
                </div>
            </div>
            <!--  -->
            <div class="info_btn">
                <el-button class="btnBgColor_blue" size="small" type="primary" @click="affirmBtn" :loading="subLoading">确 定</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import FileUpload from '@/components/file_upload/index.vue'// 上传
export default {
    components: {
        FileUpload
    },
    data() {
        return {
            id: null,
            // 
            info: {
                name: '',
                label: '',
                audio_url: '',
                avatar: '',
                is_vip: '',
                is_recom: '',
                is_free: '',
                price: '',  //钻石
                hits: '',  //热度
                pid: '',  //专辑
                tts_platform: 2, //声音类型
                category_id: '',  //归属类型
                scenes: '', //场景
                // audio_conf: '', //配置
                identity:'', //声音标识
                audio_url_name: '',
            },
            platformList: [
                {id:2,name:"微软语音"},
                {id:1,name:"阿里语音"},
                {id:3,name:"克隆语音"},
            ],
            vipList: [
                {
                    value: 1,
                    label: "是"
                },
                {
                    value: 2,
                    label: "否"
                }
            ],
            freeList: [
                {
                    value: 1,
                    label: "是"
                },
                {
                    value: 2,
                    label: "否"
                }
            ],
            recomList: [
                {
                    value: 1,
                    label: "是"
                },
                {
                    value: 2,
                    label: "否"
                }
            ],
            categoryList: [], // 归属类型
            albumList: [], // 归属类型
            //
            subLoading: false,
            // 音频播放
            audioPlay: null
        };
    },
    created() {
        this.getCategory()
        this.getAlbum()
        if (this.$route.query.id) {
            this.id = this.$route.query.id
            this.getInfo()
        }
    },
    mounted() {

    },
    methods: {
        // 选择专辑  重新赋值
        changePid(val) {
            if (val) {
                this.$marketApi.marketAudioInfo(val).then(res => {
                    if (res.code == 1000) {
                        this.info = res.result
                        this.info.category_id = this.info.category_id?.join(',').split(',')
                        this.info.label = this.info.label.replace('|', '，');
                        this.info.pid = val
                    } else {
                        this.$errMsg(res.message)
                    }
                })
            }
        },
        // 获取类型
        getCategory() {
            this.$marketApi.marketAudioCategory().then(res => {
                if (res.code == 1000) {
                    // 归属类型
                    this.categoryList = res.result
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 编辑获取详情
        getInfo() {
            this.$marketApi.marketAudioInfo(this.id).then(res => {
                if (res.code == 1000) {
                    this.info = res.result
                    this.info.category_id = this.info.category_id?.join(',').split(',')
                    this.info.label = this.info.label.replace('|', '，');
                    if (this.info.pid == 0) {
                        this.info.pid = ''
                    }
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 获取专辑
        getAlbum() {
            this.$marketApi.marketAudioAlbum().then(res => {
                if (res.code == 1000) {
                    // 归属类型
                    this.albumList = res.result
                } else {
                    this.$errMsg(res.message)
                }
            })
        },
        // 确定
        affirmBtn() {
            var that = this
            if (that.info.name == '') {
                that.$warMsg('请输入声音名称')
                return
            } else if (that.info.label == '') {
                that.$warMsg('请输入声音标签')
                return
            } else if (that.info.hits == '') {
                that.$warMsg('请输入点击热度')
                return
            } else if (that.info.is_vip == '') {
                that.$warMsg('请选择是否会员')
                return
            } else if (that.info.is_recom == '') {
                that.$warMsg('请选择是否推荐')
                return
            } else if (that.info.is_free == '') {
                that.$warMsg('请选择是否免费')
                return
            } else if (that.info.category_id == null) {
                that.$warMsg('请选择归属类型')
                return
            } else if (that.info.avatar == '') {
                that.$warMsg('请上传头像')
                return
            } else if (that.info.audio_url == '') {
                that.$warMsg('请上传声音')
                return
            } else if (that.info.is_free == 2 && that.info.price == '') {
                that.$warMsg('请输入钻石数')
                return
            } else if (that.info.scenes == '') {
                that.$warMsg('请输入应用场景')
                return
            } else if (that.info.identity == '') {
                that.$warMsg('请输入发音人标识信息')
                return
            }
            that.subLoading = true
            if (that.id) {
                // 编辑
                let obj = JSON.parse(JSON.stringify(this.info))
                obj.label = obj.label.replace(/[,，]/g, '|');
                var params = {
                    ...obj
                }
                that.$marketApi.putMarketAudio(params, that.id).then(res => {
                    that.subLoading = false
                    if (res.code == 1000) {
                        that.$succMsg(res.message)
                        that.backBtn()
                    } else {
                        that.$errMsg(res.message)
                    }
                })
            } else {
                // 添加
                let obj = JSON.parse(JSON.stringify(this.info))
                obj.label = obj.label.replace(/[,，]/g, '|');
                var params = {
                    ...obj
                }
                that.$marketApi.postMarketAudio(params).then(res => {
                    that.subLoading = false
                    if (res.code == 1000) {
                        that.$succMsg(res.message)
                        that.backBtn()
                    } else {
                        that.$errMsg(res.message)
                    }
                })
            }
        },
        // 上传头像
        successUploadImg(item) {
            this.info.avatar = item.long_url
        },
        // 上传声音
        successUploadAudio(item) {
            this.info.audio_url = item.long_url
            this.info.audio_url_name = item.name
        },
        limitNum(e, type) {
            if (type == 'hits') {
                this.info.hits = e.replace(/[^0-9]/g, '')
            }
            if (type == 'price') {
                this.info.price = e.replace(/[^0-9]/g, '')
            }
        },
        // 播放音频
        playAudio(url){
            if(this.audioPlay){
                this.audioPlay.pause()
                this.audioPlay = null
            }else{
                this.audioPlay = new Audio(url)
			    this.audioPlay.play()
            }
        },
        // 返回
        backBtn() {
            this.$router.back()
        },
    },
    beforeDestroy(){
        if(this.audioPlay){
            this.audioPlay.pause()
            this.audioPlay = null
        }
    },
};
</script>

<style scoped lang="scss">
.add_edit_vue {
    width: 100%;
    .back_btn {
        cursor: pointer;
        .el-icon-arrow-left {
            margin-right: 5px;
        }
    }
    .back_btn:hover {
        .el-icon-arrow-left {
            font-weight: 800;
            color: $blueColor1;
        }
    }
    .main_info {
        width: 100%;
        padding: 0 20px;
        .info_basic {
            margin-top: 20px;
            width: 100%;
            padding-bottom: 20px;
            border-radius: 10px;
            background: #f9f9f9;
            display: flex;
            flex-wrap: wrap;
            .basic_text {
                width: 600px;
                padding-top: 20px;
                padding-left: 50px;
                display: flex;
                align-items: center;
                .left {
                    width: 130px;
                    padding-right: 20px;
                    font-size: 14px;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    text-align: right;
                    span {
                        color: #e51d41;
                    }
                }
                .right {
                    width: 370px;
                    .el-input {
                        width: 100%;
                    }

                    .el-select {
                        width: 100%;
                    }

                }
            }
        }

        .info_btn {
            width: 100%;
            padding: 20px;
            display: flex;
            justify-content: flex-end;
        }
    }
    .pd70 {
        padding-bottom: 70px;
    }
    .el-upload-audio {
        position: relative;
        width: 100%;
        height: 100%;
        padding: 10px;
        .img {
            width: 60px;
            height: 60px;
            margin: 0 auto;
        }
        p {
            font-size: 12px;
            color: #999999;
            padding-top: 6px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
        }
        .succ_btn{
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
            width: 100%;
            height: 100%;
            padding: 0 10px;
            border-radius: 5px;
            background: rgba($color: #000000, $alpha: 0.5);
            display: none;
            align-items: center;
            justify-content: space-around;
            i{
                font-size: 26px;
                color: #fff;
            }
        }
    }
    .el-upload-audio:hover .succ_btn{
        display: flex;
    }
}
</style>
